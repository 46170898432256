<template>
  <lifestyle1-template
    :cigarette-intake="cigaretteIntake"
    :alcohol-intake="alcoholIntake"
    :regular-exercise="regularExercise"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import Lifestyle1Template from '@/modules/questionnaire/components/steps/questionnaire/oxidative-stress/lifestyle-1/Lifestyle1Template';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { requiredField } = fieldBuilder;

const FIELDS = [
  requiredField('cigaretteIntake'),
  requiredField('alcoholIntake'),
  requiredField('regularExercise')
];

export default {
  name: 'Lifestyle1',
  components: { Lifestyle1Template },
  mixins: [makeStep(FIELDS)],
  watch: {
    cigaretteIntake(newValue, oldValue) {
      if (!oldValue && this.alcoholIntake && this.regularExercise) {
        this.showNextStep();
      } else {
        this.scrollTo('#alcohol-intake');
      }
    },
    alcoholIntake(newValue, oldValue) {
      if (!oldValue && this.cigaretteIntake && this.regularExercise) {
        this.showNextStep();
      } else {
        this.scrollTo('#regular-exercise');
      }
    },
    regularExercise(newValue, oldValue) {
      if (!oldValue && this.cigaretteIntake && this.alcoholIntake) {
        this.showNextStep();
      }
    }
  }
};
</script>
