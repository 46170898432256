<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['cigaretteIntake'])">
      <single-answer-question
        id="cigarette-intake"
        title="label.cigaretteIntake"
        :value="cigaretteIntake"
        :options="$options.cigaretteIntakeOptions"
        dysfunction="oxidative-stress"
        yes-no
        @input="onFieldChange('cigaretteIntake', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['alcoholIntake'])">
      <single-answer-question
        id="alcohol-intake"
        title="label.alcoholIntake"
        :value="alcoholIntake"
        :options="$options.alcoholIntakeOptions"
        dysfunction="oxidative-stress"
        yes-no
        @input="onFieldChange('alcoholIntake', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['regularExercise'])">
      <single-answer-question
        id="regular-exercise"
        title="label.regularExercise"
        :value="regularExercise"
        :options="$options.regularExerciseOptions"
        dysfunction="oxidative-stress"
        yes-no
        @input="onFieldChange('regularExercise', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import {
  NO_RESPONSE_BINDINGS,
  YES_RESPONSE_BINDINGS
} from '@/modules/questionnaire/constants/questions';

import cigaretteIntakeYesImage from '@/modules/questionnaire/assets/images/oxidative-stress/lifestyle-1/cigarette-intake-yes.svg';
import cigaretteIntakeNoImage from '@/modules/questionnaire/assets/images/oxidative-stress/lifestyle-1/cigarette-intake-no.svg';

import alcoholIntakeYesImage from '@/modules/questionnaire/assets/images/oxidative-stress/lifestyle-1/alcohol-intake-yes.svg';
import alcoholIntakeNoImage from '@/modules/questionnaire/assets/images/oxidative-stress/lifestyle-1/alcohol-intake-no.svg';

import regularExerciseYesImage from '@/modules/questionnaire/assets/images/oxidative-stress/lifestyle-1/regular-exercise-yes.svg';
import regularExerciseNoImage from '@/modules/questionnaire/assets/images/oxidative-stress/lifestyle-1/regular-exercise-no.svg';

const CIGARETTE_INTAKE_OPTIONS = [
  { ...YES_RESPONSE_BINDINGS, image: cigaretteIntakeYesImage },
  { ...NO_RESPONSE_BINDINGS, image: cigaretteIntakeNoImage }
];

const ALCOHOL_INTAKE_OPTIONS = [
  { ...YES_RESPONSE_BINDINGS, image: alcoholIntakeYesImage },
  { ...NO_RESPONSE_BINDINGS, image: alcoholIntakeNoImage }
];

const REGULAR_EXERCISE_OPTIONS = [
  { ...YES_RESPONSE_BINDINGS, image: regularExerciseYesImage },
  { ...NO_RESPONSE_BINDINGS, image: regularExerciseNoImage }
];

export default {
  name: 'Lifestyle1Template',
  components: { SingleAnswerQuestion, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  cigaretteIntakeOptions: CIGARETTE_INTAKE_OPTIONS,
  alcoholIntakeOptions: ALCOHOL_INTAKE_OPTIONS,
  regularExerciseOptions: REGULAR_EXERCISE_OPTIONS,
  props: {
    cigaretteIntake: {
      type: String,
      required: true
    },
    alcoholIntake: {
      type: String,
      required: true
    },
    regularExercise: {
      type: String,
      required: true
    }
  }
};
</script>
